'use client'

import { useEffect, useState } from 'react'

type Theme = 'light' | 'dark'

function useTheme() {
  const [theme, setTheme] = useState<Theme | null>(() => {
    if (typeof window === 'undefined') return null
    return (
      (document.cookie
        .split('; ')
        .find((row) => row.startsWith('theme='))
        ?.split('=')[1] as Theme) ||
      (window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light')
    )
  })

  useEffect(() => {
    if (theme) {
      document.cookie = `theme=${theme}; path=/;`
      document.querySelector('html')?.setAttribute('data-theme', theme)
    } else {
      setTheme(
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light',
      )
    }
  }, [theme])

  return {
    theme,
    setTheme,
  }
}

export default useTheme
