'use client'

import { ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { getAuth0Params } from '@/utils'

export interface WithAuth0Props {
  children: ReactNode
}

export default function WithAuth0({ children }: WithAuth0Props) {
  const auth0Params = getAuth0Params()
  return <Auth0Provider {...auth0Params}>{children}</Auth0Provider>
}
