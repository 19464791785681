import { PropsWithChildren, useCallback, useReducer } from 'react'

import {
  DEFAULT_MODAL_STATE,
  MODAL_ACTION_ENUM,
  ModalContext,
  ModalOptions,
  modalReducer,
} from './context'
import { Modal } from '.'

export function ModalProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(modalReducer, DEFAULT_MODAL_STATE)

  const openModal = useCallback(
    (options: ModalOptions) => {
      dispatch({
        type: MODAL_ACTION_ENUM.OPEN_MODAL,
        payload: options,
      })
      return () => {
        dispatch({
          type: MODAL_ACTION_ENUM.CLOSE_MODAL,
        })
      }
    },
    [dispatch],
  )

  const closeModal = useCallback(() => {
    dispatch({
      type: MODAL_ACTION_ENUM.CLOSE_MODAL,
    })
  }, [dispatch])

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      <Modal
        open={state.open}
        onOpenChange={(opened) => {
          if (!opened) {
            closeModal()
          }
        }}
      >
        {state.options?.content}
      </Modal>
    </ModalContext.Provider>
  )
}
