import { useAtom, useSetAtom } from 'jotai'
import Dialog from '@/components/dialog'
import Button from '@/components/button'
import { getCreditDialogOpenAtom, insufficientDialogOpenAtom } from '@/atoms'
import useActivePlan from '@/hooks/useActivePlan'
import { MEMBERSHIP_TIER_IDS } from '@/constants'
import { useRouter } from 'next/navigation'

export default function InsufficientDialog() {
  const setGetCreditDialogOpen = useSetAtom(getCreditDialogOpenAtom)
  const [insufficientDialogOpen, setInsufficientOpen] = useAtom(
    insufficientDialogOpenAtom,
  )
  const { data: activePlan } = useActivePlan()
  const router = useRouter()

  const isProPlan = Boolean(activePlan?.tier_id === MEMBERSHIP_TIER_IDS.PRO)

  const openGetCredit = () => {
    setInsufficientOpen(false)
    setGetCreditDialogOpen(true)
  }

  const gotoUpgrade = () => {
    setInsufficientOpen(false)
    router.push('/membership')
  }

  return (
    <Dialog
      open={insufficientDialogOpen}
      title='Insufficient credits'
      titleClassName='mb-3'
      className='gap-0 w-100 md:w-100 p-6'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={(open) => setInsufficientOpen(open)}
    >
      <div className='w-[352px] mb-8 text-text-subdued'>
        You’ve run out of credits to complete this creation. Please get more
        credits to continue.
      </div>
      <div className='flex gap-3'>
        {isProPlan ? null : (
          <Button
            variant='outline'
            className='flex-1 rounded-md'
            onClick={openGetCredit}
          >
            Get credits
          </Button>
        )}
        <Button
          variant='primary'
          className='flex-1 rounded-md'
          onClick={gotoUpgrade}
        >
          Upgrade
        </Button>
      </div>
    </Dialog>
  )
}
