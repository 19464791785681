import Dialog from '@/components/dialog'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-large.svg'
import IconX from '@haiper/icons-svg/icons/band/x.svg'
import { cls, openNewTab, setLocalStorage, whisper } from '@/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Button from '@/components/button'
import { CACHE_KEYS, X_LINK } from '@/constants'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import useAmplitude from '@/hooks/useAmplitude'
import Image from '../image'
import usePopups from '@/hooks/usePopups'

export default function FollowUsDialog() {
  const { track } = useAmplitude()
  const { showFollowUsDialog, closeFollowUsDialog } = usePopups()

  const handleClose = useCallback(() => {
    track('click:follow-us:close')
    closeFollowUsDialog()
    setLocalStorage(CACHE_KEYS.FOLLOW_US_DIALOG, false)
  }, [track, closeFollowUsDialog])

  const gotoX = useCallback(() => {
    closeFollowUsDialog()
    setLocalStorage(CACHE_KEYS.FOLLOW_US_DIALOG, false)
    openNewTab(X_LINK)
  }, [closeFollowUsDialog])

  const viewEventTrackedRef = useRef(false)

  const { isBelowMd } = useBreakpoint('md')

  const previewImg = 'https://scontent.haiper.ai/webapp/images/follow-us/haiper-x-follow-us@2x.png'
  const previewImgPc = 'https://scontent.haiper.ai/webapp/images/follow-us/haiper-x-follow-us-pc@2x.png'

  const previewUrl = useMemo(() => {
    return isBelowMd ? previewImg : previewImgPc
  }, [isBelowMd, previewImg, previewImgPc])

  const handleOkButtonClick = useCallback(() => {
    track('click:follow-us:goto-x')
    gotoX()
  }, [track, gotoX])

  const handleBannerClick = useCallback(() => {
    track('click:follow-us:banner')
    gotoX()
  }, [track, gotoX])

  useEffect(() => {
    if (showFollowUsDialog && !viewEventTrackedRef.current) {
      track('view:follow-us-dialog')
      viewEventTrackedRef.current = true
    }
  }, [showFollowUsDialog, track])

  return (
    <Dialog
      open={showFollowUsDialog}
      title={
        <div className='flex flex-col gap-6'>
          <Image
            className='cursor-pointer rounded-md border border-border h-[175px] md:h-[298px]'
            src={previewUrl}
            alt='follow-us'
            onClick={handleBannerClick}
          />
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1'>
              <span className='font-bold text-2xl leading-[normal]'>Follow us on</span>
              <div className='flex size-7 items-center justify-center'>
                <IconX />
              </div>
            </div>
            <Button
              variant='transparent'
              className={cls(
                'p-0 size-10 rounded-lg focus-visible:shadow-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted border-0 shadow-none',
              )}
              aria-label='dialog close button'
              onClick={handleClose}
            >
              <IconClose className='size-6 text-icon' />
              <span className='sr-only'>Close</span>
            </Button>
          </div>
        </div>
      }
      titleClassName='mb-3'
      className='gap-0 w-[343px] md:w-[578px] p-4 md:p-6'
      closeClassName='hidden'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={(open) => {
        if (!open) {
          closeFollowUsDialog()
        }
      }}
    >
      <div className='w-full mt-3 text-text flex flex-col gap-6 md:gap-8 text-body-md tracking-15'>
        <ul className='list-disc list-inside px-1'>
          <li>
            📢 Get the latest <span className='font-bold'>product updates</span> before everyone else
          </li>
          <li>
            💎 Increase your chance of winning a <span className='font-bold'>Pro Membership</span>
          </li>
        </ul>
        <Button
          variant='primary'
          className='w-full text-body-md font-normal tracking-15 align-middle'
          onClick={handleOkButtonClick}
        >
          🆗 Go to X
        </Button>
      </div>
    </Dialog>
  )
}
