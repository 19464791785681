import { PoNVoid } from '@/types'
import IconArrowLeft from '@haiper/icons-svg/icons/outline/arrow-left.svg'
import { cls } from '@/utils'

interface BackProps {
  className?: string
  onBack: () => PoNVoid
}

export default function Back({ onBack, className }: BackProps) {
  return (
    <div
      aria-label='back arrow'
      className={cls(
        'flex gap-1 items-center px-3 py-2 cursor-pointer bg-surface hover:bg-surface-hover rounded-md',
        className,
      )}
      role='button'
      onClick={onBack}
    >
      <IconArrowLeft />
      <p className='text-text text-body-md'>Back</p>
    </div>
  )
}
