import { FC, useEffect, useRef } from 'react'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-small.svg'
import IconClockAdd from '@haiper/icons-svg/icons/outline/clock-add.svg'
import IconFeatures from '@haiper/icons-svg/icons/outline/features.svg'
import IconAiText from '@haiper/icons-svg/icons/outline/ai-text.svg'
import Dialog from '@/components/dialog'
import Button from '@/components/button'
import { useAtom } from 'jotai'
import { versionDialogOpenAtom } from '@/atoms'
import Video from '../video'
import { getHashOrigin, setLocalStorage } from '@/utils'
import { useRouter } from 'next/navigation'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import Hls, {
  HlsConfig,
  LoaderCallbacks,
  LoaderConfiguration,
  LoaderContext,
} from 'hls.js'

class CustomLoader extends Hls.DefaultConfig.loader {
  constructor(config: HlsConfig) {
    super(config)
  }

  load(
    context: LoaderContext,
    config: LoaderConfiguration,
    callbacks: LoaderCallbacks<LoaderContext>,
  ) {
    const url = new URL(context.url)
    const dStr = getHashOrigin()
    url.searchParams.append('d', dStr)
    context.url = url.toString()
    super.load(context, config, callbacks)
  }
}

const hlsConfig = {
  loader: CustomLoader,
}

interface IProps {}

const VersionDialog: FC<IProps> = () => {
  const [versionDialogOpen, setVersionDialogOpen] = useAtom(
    versionDialogOpenAtom,
  )
  const { data: profile } = useCachedMyProfile()
  const videoRef = useRef<any>()
  const intervalRef = useRef<any>()
  const router = useRouter()

  const closeDialog = () => {
    setLocalStorage('versionShow', new Date())
    setVersionDialogOpen(false)
  }

  const startCreate = () => {
    setLocalStorage('versionShow', new Date())
    setVersionDialogOpen(false)
    router.push('/creations')
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      if (videoRef.current) {
        const hls = new Hls(hlsConfig)
        hls.config.maxMaxBufferLength = 10
        hls.attachMedia(videoRef.current)
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          hls.loadSource(
            'https://static2.haiper.ai/public/hls/haiper-0717/index.m3u8?t=111',
          )
          hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            videoRef.current.play().catch(() => {})
          })
        })
      }
    })
  }, [])

  return (
    <Dialog
      footer={null}
      open={versionDialogOpen}
      closeClassName='hidden'
      className='w-[578px] md:w-[578px] p-4 md:p-6'
    >
      <div>
        <video
          ref={videoRef}
          autoPlay
          muted
          playsInline
          loop
          poster='https://scontent.haiper.ai/webapp/videos/introduce/20240717.jpg'
          className='mb-4 md:mb-6 rounded-md'
        />
        <div className='flex justify-between h-7 items-center mb-4 md:mb-6'>
          <div className='text-heading-2xl font-bold'>
            Introducing Haiper 1.5
          </div>
          <Button className='px-2 text-icon-subdued' onClick={closeDialog}>
            <IconClose />
          </Button>
        </div>
        <div className='mb-6 md:mb-8'>
          <div className='flex gap-2 mb-4'>
            <IconClockAdd className='text-surface-primary' />
            <div>
              <div className='tracking-15 mb-2'>Longer video</div>
              <div className='text-text-subdued'>
                8 seconds video generation and 4 seconds extension.
              </div>
            </div>
          </div>
          <div className='flex gap-2 mb-4'>
            <IconFeatures className='text-surface-primary' />
            <div>
              <div className='tracking-15 mb-2'>
                Bigger resolution with more detail
              </div>
              <div className='text-text-subdued'>
                Enhance your videos to 1080p with more detail.
              </div>
            </div>
          </div>
          <div className='flex gap-2 mb-4'>
            <IconAiText className='text-surface-primary' />
            <div>
              <div className='tracking-15 mb-2'>Text to Image</div>
              <div className='text-text-subdued'>
                Create image by text prompt using our new image model.
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-center'>
          <Button
            variant='primary'
            className='w-full md:w-50'
            onClick={startCreate}
          >
            Start creating
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default VersionDialog
