'use client'

import Dialog from '@/components/dialog'
import { cls, getMailToLink } from '@/utils'
import useAmplitude from '@/hooks/useAmplitude'
import IconError from '@haiper/icons-svg/icons/outline/error.svg'
import Button from '@/components/button'
import { showBlockedDialogAtom } from '@/atoms'
import { useAtom } from 'jotai'
import { LINKS } from '@/constants'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import { useMemo } from 'react'

export function BlockedDialog() {
  const { track } = useAmplitude()
  const [showBlockedDialog, setShowBlockedDialog] = useAtom(showBlockedDialogAtom)
  const { data: profile } = useCachedMyProfile()

  const contactLink = useMemo(() => {
    return getMailToLink({
      email: LINKS.getInTouch,
      subject: `Account Suspension Appeal - [${profile?.user_id ?? ''}]`,
      body: 'Why I believe this suspension is unjust:',
    })
  }, [profile])

  return (
    <Dialog
      open={showBlockedDialog}
      title={
        <div className='flex flex-col gap-4'>
          <div aria-label='icon' className='size-12 rounded-full bg-surface-critical p-3'>
            <IconError className='size-full text-icon-critical' />
          </div>
          <span className='text-heading-lg font-bold'>Your account has been suspended</span>
        </div>
      }
      titleClassName='mb-1'
      className={cls('gap-0')}
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={setShowBlockedDialog}
    >
      <div className='relative w-full flex flex-col gap-8'>
        <div className='relative w-full text-text-subdued text-body-md'>
          <span>Your account is suspended for violating our</span>
          <a
            className='text-text-interactive inline px-0.5'
            href={LINKS.termsOfUse}
            target='_blank'
            rel='noopener'
            onClick={() => {
              track('click:blocked:terms')
            }}
          >
            <span className='text-body-md'>Terms and Conditions. </span>
          </a>
          <span>
            If you believe this is a mistake, please
            <a
              className='text-text-interactive inline px-0.5'
              href={contactLink}
              onClick={() => {
                track('click:blocked:get-in-touch')
              }}
            >
              get in touch with us.
            </a>
          </span>
        </div>
        <Button variant='primary' onClick={() => setShowBlockedDialog(false)}>
          Confirm
        </Button>
      </div>
    </Dialog>
  )
}
