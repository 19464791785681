import { cls } from '@/utils'
import { Loading as BaseLoading } from '@lemonsqueezy/wedges'

export interface LoadingProps {
  className?: string
}

export default function Loading({ className }: LoadingProps) {
  return (
    <BaseLoading
      className={cls('pointer-events-none self-center', className)}
    />
  )
}
