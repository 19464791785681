import { getTemplates } from '@/service/template.service'
import useService, { useCachedService } from '@banyudu/use-service'
import useInfinite from './useInfinite'
import useAuthedSkip from './useAuthedSkip'
import { Pagination, Template } from '@/types'

const validator = (params: Parameters<typeof getTemplates>[0]) => Boolean(params?.category)
const useTemplates = useService(getTemplates, validator)
export const useCachedTemplates = useCachedService(getTemplates, useAuthedSkip(validator))

const getAllTemplates = async () => {
  const res = await getTemplates({ limit: 999999 })
  return res
}

export const useCachedAllTemplates = useCachedService<Pagination<Template>>(getAllTemplates, useAuthedSkip(), {
  keepPreviousData: true,
})

export const useCachedAllTemplatesWithoutAuthCheck = useCachedService<Pagination<Template>>(
  getAllTemplates,
  undefined,
  {
    keepPreviousData: true,
  },
)

export const useInfiniteTemplates = useInfinite(useTemplates, {
  rowKey: 'template_id',
})
