'use client'

import Dialog from '@/components/dialog'
import Button from '@/components/button'
import IconLogin from '@haiper/icons-svg/icons/outline/login.svg'
import { useAtom } from 'jotai'
import { loginDialogOpenAtom } from '@/atoms'
import { cls } from '@/utils'
import { useCallback } from 'react'
import { usePathname } from 'next/navigation'
import useAuth0Auth from '@/hooks/useAuth0Auth'

export default function LoginDialog() {
  const [loginDialogOpen, setLoginDialogOpen] = useAtom(loginDialogOpenAtom)
  const pathname = usePathname()

  const handleCancel = useCallback(() => {
    setLoginDialogOpen(false)
  }, [setLoginDialogOpen])

  const { login } = useAuth0Auth()

  const handleLogin = useCallback(() => {
    login()
  }, [login])

  if (pathname.startsWith('/auth/')) {
    return null
  }

  return (
    <Dialog
      open={loginDialogOpen}
      title={
        <div className='flex flex-col gap-3'>
          <div aria-label='icon' className='size-12 rounded-full bg-surface-active p-3'>
            <IconLogin className='size-full text-icon-interactive ' />
          </div>
          <div className='w-full flex flex-col gap-1'>
            <div className='text-heading-lg font-bold'>Login Required</div>
          </div>
        </div>
      }
      titleClassName='mb-6'
      className={cls('gap-0 md:w-[343px] p-4')}
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={(open) => setLoginDialogOpen(open)}
    >
      <div className='flex gap-3'>
        <Button variant='outline' className='flex-1 rounded-md' onClick={handleCancel}>
          <span className=''>Cancel</span>
        </Button>
        <Button variant='primary' className='flex-1 rounded-md' onClick={handleLogin}>
          <span className=''>Login</span>
        </Button>
      </div>
    </Dialog>
  )
}
