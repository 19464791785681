'use client'

import { getLocalStorage, isProduction, setLocalStorage } from '@/utils'
import { useSearchParams } from 'next/navigation'
import Script from 'next/script'
import { useEffect } from 'react'

declare var VConsole: any

export default function WeChatVConsoleScript() {
  const searchParams = useSearchParams()
  useEffect(() => {}, [searchParams])

  const enableVConsole = getLocalStorage('vconsole') === 'true'

  const vconsole = searchParams.get('vconsole')

  useEffect(() => {
    if (vconsole === 'true') {
      setLocalStorage('vconsole', 'true')
    } else if (vconsole === 'false') {
      setLocalStorage('vconsole', 'false')
    }
  }, [vconsole])

  if (typeof location === 'undefined' || isProduction) {
    return null
  }

  if (!enableVConsole && vconsole !== 'true') {
    return null
  }

  return (
    <Script
      src='https://unpkg.com/vconsole@latest/dist/vconsole.min.js'
      onReady={() => {
        new VConsole()
      }}
    />
  )
}
