import useConfig, { useCachedConfig } from './useConfig'

const useMarketingEventsInKV = () => {
  return useConfig<haiper.MarketingEvent[]>('marketing-events')
}

export const useCachedMarketingEventsInKV = () => {
  return useCachedConfig<haiper.MarketingEvent[]>('marketing-events')
}

export default useMarketingEventsInKV
