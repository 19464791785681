let job: Promise<any> | null = null

export async function initMocks() {
  if (process.env.NEXT_PUBLIC_API_MOCKING !== 'enabled') {
    return Promise.resolve()
  }
  const startMocking = async () => {
    if (typeof window === 'undefined') {
      const { server } = await import('./server')
      return server.listen({ onUnhandledRequest: 'bypass' })
    } else {
      const { worker } = await import('./browser')
      return worker.start({ onUnhandledRequest: 'bypass' })
    }
  }

  if (job === null) {
    job = startMocking()
  }
  return job
}
