'use client'

import Dialog from '@/components/dialog'
import { cls } from '@/utils'
import useAmplitude from '@/hooks/useAmplitude'
import { subscriptionDialogContentAtom } from '@/atoms'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'
import UpgradeButton from '@/components/upgrade-button'
import { ErrorCodeEnum } from '@/types'
import useActivePlan from '@/hooks/useActivePlan'
import { MEMBERSHIP_TIER_IDS } from '@/constants'
import Button from '../button'

export function SubscriptionDialog() {
  const { track } = useAmplitude()
  const [subscriptionDialogContent, setSubscriptionDialogContent] = useAtom(subscriptionDialogContentAtom)

  const { data: activePlan, loading } = useActivePlan()

  const handleClose = useCallback(() => {
    setSubscriptionDialogContent(null)
  }, [setSubscriptionDialogContent])

  useEffect(() => {
    if (subscriptionDialogContent) {
      track('view:membership:subscription-required', {
        code: subscriptionDialogContent.code ?? '',
        message: subscriptionDialogContent.message,
      })
    }
  }, [track, subscriptionDialogContent])

  const isFreePlan = useMemo(() => !activePlan || activePlan.is_free, [activePlan])

  const { title, content } = useMemo(() => {
    if (loading) {
      return {
        title: '',
        content: '',
      }
    }
    if (subscriptionDialogContent?.code === ErrorCodeEnum.QUOTA_EXCEEDED && isFreePlan) {
      return {
        title: 'Upgrade to create more videos',
        content:
          'You are on the Free plan, which lets you to create 5 videos per day. Please upgrade to Haiper Membership to create more videos.',
      }
    }

    if (subscriptionDialogContent?.code === ErrorCodeEnum.RATE_EXCEEDED) {
      if (isFreePlan) {
        return {
          title: 'Upgrade to expand the concurrency',
          content:
            'You are on the Free plan, which lets you to create 3 videos at the same time. Please upgrade to create more videos simultaneously.',
        }
      } else if (activePlan?.tier_id === MEMBERSHIP_TIER_IDS.EXPLORER) {
        return {
          title: 'Upgrade to expand the concurrency',
          content:
            'You are on the Explorer plan, which lets you to create 5 videos at the same time. Please upgrade to create more videos simultaneously.',
        }
      } else if (activePlan?.tier_id === MEMBERSHIP_TIER_IDS.PRO) {
        return {
          title: 'Upgrade to expand the concurrency',
          content:
            'You are on the Pro plan, which lets you to create 10 videos at the same time. Please upgrade to create more videos simultaneously.',
        }
      } else if (activePlan?.tier_id === MEMBERSHIP_TIER_IDS.ULTRA) {
        return {
          title: 'Upgrade to expand the concurrency',
          content:
            'You are on the Ultra plan, which lets you to create 15 videos at the same time. Please upgrade to create more videos simultaneously.',
        }
      } else if (activePlan?.tier_id === MEMBERSHIP_TIER_IDS.MAX) {
        return {
          title: 'Too many concurrent creations',
          content: (
            <>
              You already have 20 jobs being generated right now. Please wait until some of them are finished before
              submitting new ones.
              <br />
            </>
          ),
        }
      }
    }

    return {
      title: 'Membership required',
      content: subscriptionDialogContent?.message ?? '',
    }
  }, [subscriptionDialogContent, activePlan, isFreePlan, loading])

  return (
    <Dialog
      open={!!subscriptionDialogContent && !loading}
      title={<span className='text-heading-lg font-bold tracking-45'>{title}</span>}
      titleClassName='mb-1 font-bold text-heading-lg'
      className={cls('gap-0 w-[343px] md:w-100')}
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
    >
      <div className='relative w-full flex flex-col gap-8 text-text tracking-15'>
        <div className='text-body-md tracking-15 mt-2 leading-5 text-text-subdued'>{content}</div>
        {subscriptionDialogContent?.code === ErrorCodeEnum.RATE_EXCEEDED &&
        activePlan?.tier_id === MEMBERSHIP_TIER_IDS.MAX ? (
          <Button variant='primary' className='w-full h-10' onClick={handleClose}>
            OK
          </Button>
        ) : (
          <UpgradeButton className='w-full h-10' source='subscription-required-dialog' onClick={handleClose} />
        )}
      </div>
    </Dialog>
  )
}
