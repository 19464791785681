'use client'

import Dialog from '@/components/dialog'
import IconError from '@haiper/icons-svg/icons/outline/error.svg'
import { cls } from '@/utils'
import useAmplitude from '@/hooks/useAmplitude'
import { generationErrorDialogContentAtom } from '@/atoms'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useMemo } from 'react'
import Button from '../button'

export function GenerationErrorDialog() {
  const { track } = useAmplitude()
  const [generationErrorDialogContent, setGenerationErrorDialogContent] = useAtom(generationErrorDialogContentAtom)

  const handleClose = useCallback(() => {
    setGenerationErrorDialogContent(null)
  }, [setGenerationErrorDialogContent])

  useEffect(() => {
    if (generationErrorDialogContent) {
      track('view:membership:generation-error-required', {
        code: generationErrorDialogContent.code ?? '',
        message: generationErrorDialogContent.message,
      })
    }
  }, [track, generationErrorDialogContent])

  return (
    <Dialog
      open={!!generationErrorDialogContent}
      titleClassName='mb-1 font-bold text-heading-lg'
      className={cls('gap-0 w-[343px] md:w-100')}
      footerClassName='mt-[30px]'
      footer={null}
      title={
        <div className='flex flex-col gap-4'>
          <div aria-label='icon' className='size-12 rounded-full bg-surface-critical p-3'>
            <IconError className='size-full text-icon-critical' />
          </div>
          <span className='text-heading-lg font-bold'>Failed to create</span>
        </div>
      }
      onOpenChange={handleClose}
    >
      <div className='relative w-full flex flex-col gap-8 text-text tracking-15'>
        <div className='text-body-md tracking-15 mt-2 leading-5 text-text-subdued'>
          {generationErrorDialogContent?.message ??
            'The content may violate our usage policy, please try something else.'}
        </div>
        <Button variant='primary' className='w-full h-10' onClick={handleClose}>
          OK
        </Button>
      </div>
    </Dialog>
  )
}
