import { SOCIAL_ENTRIES } from '@/constants'
import { useMemo } from 'react'

export interface UseSocialMediaOptionsParmas {
  blacklist?: string[]
}

export const socialMediaOptions = SOCIAL_ENTRIES.map((entry) => ({
  value: entry.name,
  label: entry.label,
}))

export default function useSocialMediaOptions({ blacklist }: UseSocialMediaOptionsParmas) {
  const result = useMemo(() => {
    return socialMediaOptions.filter((item) => !blacklist?.includes(item.value))
  }, [blacklist])

  return result
}
