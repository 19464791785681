import { ReactNode, createContext, useContext } from 'react'

const needProvider = (): never => {
  throw Error('No AlertProvider is found.')
}

export interface ModalOptions {
  content?: ReactNode
  onClose?: () => void
}

export interface ModalMethods {
  openModal: (options: ModalOptions) => void
  closeModal: () => void
}

interface ModalState {
  open?: boolean
  options?: ModalOptions
}

const DEFAULT_METHODS = {
  openModal: needProvider,
  closeModal: needProvider,
}

export enum MODAL_ACTION_ENUM {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

type ModalAction =
  | {
      type: MODAL_ACTION_ENUM.OPEN_MODAL
      payload: ModalOptions
    }
  | {
      type: MODAL_ACTION_ENUM.CLOSE_MODAL
    }

export const DEFAULT_MODAL_STATE = {
  open: false,
}

export const modalReducer = (
  state: ModalState,
  action: ModalAction,
): ModalState => {
  switch (action.type) {
    case MODAL_ACTION_ENUM.OPEN_MODAL:
      return {
        ...state,
        open: true,
        options: action.payload,
      }

    case MODAL_ACTION_ENUM.CLOSE_MODAL:
      return {
        ...state,
        open: false,
      }
  }
}

export const ModalContext = createContext<ModalMethods>(DEFAULT_METHODS)
